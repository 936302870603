type SetState<T> = (orig: T) => T

// Old-school scoping-- probably not necessary in a module.
const { passErrors, passData, configureSetArray, configureSetData, getArray, getData } = (function() {
	// Poor man's global state. Don't hate as long as it stays
	// this small of an implementation
	const origArr = []
	let origD = {}

	let setArr = (p: SetState<any[]>): void => {
		origArr.splice(0, origArr.length, ...p(origArr));
	};

	let setD = (p: SetState<object>) => origD = {...origD, ...p};

	return {
		passErrors: (errors: string[]) => {
			setArr(arr => [...errors, ...arr].slice(0, 50))
		},
		passData: (data: {[key: string]: any}) => {
			setD(oldD => ({...oldD, ...data}))
		},
		configureSetArray: (newSetArr: typeof setArr) => {
			setArr = newSetArr;
		},
		configureSetData: (newSetData: typeof setD) => {
			setD = newSetData;
		},
		getArray: () => origArr,
		getData: () => origD,
	};
})();

export { passErrors, passData, configureSetArray, configureSetData, getArray, getData };
