import { setHeader } from '../../utils/api';
import {
  PROPERTIES_FETCH,
  CREATE_JOB,
} from '../actionTypes';

const privateFetchActions = [
  PROPERTIES_FETCH,
  CREATE_JOB,
];

export default store => next => (action) => {
  if (privateFetchActions.includes(action.type)) {
    setHeader('Authorization', `Bearer ${store.getState().user.auth.token}`);
  }
  return next(action);
};
