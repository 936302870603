import { combineReducers } from 'redux';
import user from './user';
import jobCreation from './jobCreation';
import browseJobs from './browseJobs';

export default combineReducers({
  user,
  jobCreation,
  browseJobs
});
