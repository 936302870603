import update from 'immutability-helper'

import { homes as dummy_homes, jobs as dummy_jobs } from '../dummyData'

import { ADD_HOME } from '../actionTypes'

const initialState = {
	homes: dummy_homes,
	jobs: dummy_jobs,
}

export default function(state = initialState, action) {
	switch (action.type) {
		case ADD_HOME: {
			const id = `${Date.now()}`
			const { street_address } = action.payload
			return update(state, {
				homes: {
					$push: [
						{
							street_address,
							id,
							avatar_uri: state.homes[0].avatar_uri,
						},
					],
				},
			})
		}
		default:
			return state
	}
}
