/* globals require, window */ // eslint-disable-line
import { createStore as reduxCreateStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { composeWithDevTools } from 'redux-devtools-extension'
import rootReducer from './reducers'
import rootSaga from './sagas/sagas'
import saveAuthToken from './middleware/auth'

const sagaMiddleware = createSagaMiddleware()

const createStore = () =>
	reduxCreateStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware), applyMiddleware(saveAuthToken)))

export const store = createStore()

sagaMiddleware.run(rootSaga)

export default store
