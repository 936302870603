import { all } from 'redux-saga/effects';
import { watchFetchUserAsync } from './user';
import { watchFetchJobsAsync, watchCreateJobAsync, watchConfirmJobAsync } from './jobs';
import { watchFetchPropertiesAsync, watchUploadPictureAsync } from './properties';

export default function* rootSaga() {
  yield all([
    watchFetchUserAsync(),
    watchFetchJobsAsync(),
    watchCreateJobAsync(),
    watchFetchPropertiesAsync(),
    watchUploadPictureAsync(),
    watchConfirmJobAsync(),
  ]);
}
