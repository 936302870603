import 'cross-fetch/polyfill'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { ApolloLink, split } from 'apollo-link'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import gql from 'graphql-tag'
import { withClientState } from 'apollo-link-state'
import { getMainDefinition } from 'apollo-utilities'
import { WebSocketLink } from 'apollo-link-ws'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import jwt_decode from 'jwt-decode'
import { onError } from 'apollo-link-error';
import { passErrors } from '../components/AdminPanel/globalState';

const httpLink = createHttpLink({
	uri: process.env.GATSBY_CLIENT_BASE_URL
})

const GRAPHQL_ENDPOINT = process.env.GATSBY_CLIENT_BASE_URL.replace(
	'http',
	'ws'
)

export const GET_TOKEN = gql`
	{
		token
	}
`

export const GET_SELECTED_OPTIONS = gql`
	{
		selectedOptions
	}
`

export const GET_EXPANDED_PANELS = gql`
	{
		expandedPanels
	}
`

export enum JobsVisibilityFilter {
	none = '',
	needs_bidding = 'needs_bidding',
	open = 'open',
	completed = 'completed',
	job_history = 'job_history',
}

export const GET_JOBS_VISIBILITY_FILTER = gql`
	{
		jobsVisibilityFilter @client
	}
`
export type GetJobsVisibilityFilterData = { jobsVisibilityFilter: JobsVisibilityFilter }

export const GET_CHECKED_OPTIONS = gql`
	{
		checkedOptions
	}
`
export const GET_CHECKED_ADDITIONAL_INFO = gql`
	{
		checkedAdditionalInfo
	}
`
export const getLocalToken = () => typeof window !== 'undefined' ? sessionStorage.token : undefined
export const getDecodedToken = () => {
	const t = getLocalToken()
	if (t) {
		return jwt_decode(getLocalToken())
	}
	return undefined
}

let tokenUser = ''
let tokenRole: 'CONTRACTOR' | 'HOMEOWNER' | '' | 'ADMIN' = ''

const populateTokenInfo = () => {
	const _decoded = (getDecodedToken() || {} as any)
	tokenUser = _decoded.userId
	tokenRole = _decoded.role || (_decoded.contractorId ? 'CONTRACTOR' : 'HOMEOWNER')
}
populateTokenInfo()

export const setLocalToken = (token: string) => {
	if (typeof window !== 'undefined') {
		sessionStorage.token = token
	}
	populateTokenInfo()
}
export const getTokenUserId = () => tokenUser
export const getTokenUserRole = () => tokenRole

export const unsetLocalToken = () => {
	if (typeof window !== 'undefined') {
		sessionStorage.token = '';
	}
	tokenUser = '';
	tokenRole = ''
}


const whitelist = [
	'/signup',
	'/finalize-reset',
	'/test',
	'/measurements',
	'/demo',
	'/verify',
	'/job-rating',
	'/deals/signup',
	'/get-app',
	'/internal/designcenter',
	'/internal/viewer',
];

if (typeof window !== 'undefined') {
	if (
		window.location.pathname.length > 1 &&
		!sessionStorage.token &&
		!whitelist.some(e => window.location.pathname.includes(e))
	) {
		console.log("REDIRECTED");
		window.location.pathname = '/'
	}
}

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const token = getLocalToken()

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : ''
		}
	}
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
	const errors: string[] = []
  if (graphQLErrors)
    errors.push(...graphQLErrors.map(({ message, locations, path }) =>
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
    ))

	if (networkError) errors.push(`[Network error]: ${networkError}`);

	passErrors(errors)
});

const cache = new InMemoryCache()

let sub_client;
let wsLink;
if (typeof window !== 'undefined') {
	sub_client = new SubscriptionClient(GRAPHQL_ENDPOINT, {
		reconnect: true,
		connectionParams: {
			Authorization: `Bearer ${getLocalToken()}`,
		},
	})
	wsLink = new WebSocketLink(sub_client)
}


const stateLink = withClientState({
	cache,
	defaults: {
		isConnected: true,
		selectedOptions: [],
		expandedPanels: [],
		checkedOptions: JSON.stringify({}),
		checkedAdditionalInfo: JSON.stringify({}),
		selectedPropertyId: null,
		selectedPropertySpecs: null,
	},
	resolvers: {
		Mutation: {
			updateNetworkStatus: (_, { isConnected }, { cache }) => {
				const data = {
					networkStatus: {
						__typename: 'NetworkStatus',
						isConnected
					}
				}
				cache.writeData({ data })
				return null
			},
			updateJobCreationStatus: (
				_,
				{ option, group, groupIndex, additionalInfo },
				{ cache }
			) => {
				const currentSelected = cache.readQuery({ query: GET_SELECTED_OPTIONS })
				const currentChecked = cache.readQuery({ query: GET_CHECKED_OPTIONS })
				const currentCheckedAdditionalInfo = cache.readQuery({ query: GET_CHECKED_ADDITIONAL_INFO })
				console.log(currentCheckedAdditionalInfo)
				const newCheckedOptions = Object.assign(
					{},
					JSON.parse(currentChecked.checkedOptions),
					{
						[group.id]: option.id
					}
				)
				const data = {
					selectedOptions: [
						...currentSelected.selectedOptions.slice(0, groupIndex + 1),
						option.linksTo
					],
					expandedPanels: [option.linksTo],
					checkedOptions: JSON.stringify(newCheckedOptions),
					checkedAdditionalInfo: JSON.stringify({...JSON.parse(currentCheckedAdditionalInfo.checkedAdditionalInfo), [option.id]: additionalInfo || undefined})
				}
				cache.writeData({ data })
				return null
			},
			updateExpandedPanels: (_, { groupId }, { cache }) => {
				const currentExpanded = cache.readQuery({ query: GET_EXPANDED_PANELS })
				const included = currentExpanded.expandedPanels.includes(groupId)
				const position = currentExpanded.expandedPanels.indexOf(groupId)
				const data = {
					expandedPanels: included
						? currentExpanded.expandedPanels.filter((_, i) => i !== position)
						: currentExpanded.expandedPanels.concat([groupId])
				}
				cache.writeData({ data })
				return null
			},
			updateSelectedPropertyId: (_, { id }, { cache }) => {
				const data = {
					selectedPropertyId: id
				}
				localStorage.setItem('selectedPropertyId', id)
				cache.writeData({ data })
				return null
			}
		}
	},
})

// Load specific client entries from localStorage and write them to the client cache
cache.writeData({
	data: {
		selectedPropertyId: typeof localStorage !== 'undefined' && localStorage.getItem('selectedPropertyId'),
	},
});

let networkLink;
if (wsLink) {
	networkLink = split(
		// split based on operation type
		({ query }) => {
			const { kind, operation } = getMainDefinition(query)
			return kind === 'OperationDefinition' && operation === 'subscription'
		},
		wsLink,
		ApolloLink.from([errorLink, authLink, httpLink])
	)
}

const client = new ApolloClient({
	link: ApolloLink.from([stateLink, networkLink ? networkLink : ApolloLink.from([errorLink, authLink, httpLink])]),
	cache,
})

export default client
