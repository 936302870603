/* globals FormData */
import {
  put, takeLeading, call, takeLatest,
} from 'redux-saga/effects';
import {
  PROPERTIES_FETCH,
  PROPERTIES_FETCH_ERROR,
  PROPERTIES_FETCH_SUCCESS,
  UPLOAD_PICTURE,
} from '../actionTypes';
import Api, { setHeader } from '../../utils/api';

export function* fetchPropertiesAsync(action) {
  try {
    const res = yield call(
      Api.findProperties.find,
      action.payload,
    );
    yield put({
      type: PROPERTIES_FETCH_SUCCESS,
      payload: res.data.Properties,
    });
  } catch (e) {
    yield put({ type: PROPERTIES_FETCH_ERROR });
  }
}

export function* watchFetchPropertiesAsync() {
  yield takeLeading(PROPERTIES_FETCH, fetchPropertiesAsync);
}

export function* uploadPictureAsync(action) { // eslint-disable-line
  try {
    const formData = new FormData();
    formData.append('file', action.payload.file);
    formData.append('Project_ID', 23);
    // eslint-disable-next-line no-underscore-dangle
    setHeader('Content-Type', `multipart/form-data; boundary=${formData._boundary}`);
    yield call(Api.uploadPropertyPicture.create, formData);
  } catch (e) { // eslint-disable-line
  }
}

export function* watchUploadPictureAsync() {
  yield takeLatest(UPLOAD_PICTURE, uploadPictureAsync);
}
