module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/logo-iso.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"07e90040dfd5c4d438aef581a879faa4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"theme":{"typography":{"useNextVariants":true,"fontFamily":"\"Open Sans\", \"Helvetica\", \"Arial\", sans-serif","body1":{"fontSize":"14px"},"h6":{"fontWeight":600,"fontFamily":"\"Open Sans\", \"Helvetica\", \"Arial\", sans-serif"},"h5":{"fontWeight":600,"fontFamily":"\"Open Sans\", \"Helvetica\", \"Arial\", sans-serif"},"h4":{"fontWeight":600,"fontFamily":"\"Open Sans\", \"Helvetica\", \"Arial\", sans-serif"}},"optionDrawerWidth":{"width":"90%","@media (min-width:400px)":{"width":"330px"}},"houseCanvasWidth":{"width":"100vw","@media (min-width:850px)":{"width":"calc(100% - 330px)"}},"root":{"display":"flex"},"palette":{"primary":{"main":"#E2B985"},"secondary":{"main":"#8B6137"},"type":"light","background":{"paper":"white"}},"overrides":{"MuiTooltip":{"tooltip":{"backgroundColor":"#2c3e50","boxShadow":"0 2px 4px rgba(0,0,0,.4)","border":".5px solid rgba(255,255,255,.3)"}},"MuiPickersToolbar":{"toolbar":{"backgroundColor":"#303f9f"}},"MuiButton":{"root":{"background":"linear-gradient(#E2B985, #8B6137)","&:hover":{"backgroundColor":"#E2B985"}},"label":{"fontWeight":600,"fontFamily":"\"Open Sans\", \"Helvetica\", \"Arial\", sans-serif","color":"white"}}},"breakpoints":{"values":{"xs":0,"sm":600,"md":850,"lg":1280,"xl":1920}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"8psR1sZFSDGFHrvZOtfWbw7kNCMOAhXW","trackPage":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
