import update from 'immutability-helper';

import {
  SET_INPUT_USERNAME,
  SET_INPUT_PASSWORD,
  USER_SIGN_IN_FETCH,
  USER_SIGN_IN_FETCH_DONE,
  USER_SIGN_IN_FETCH_ERROR_USERNAME,
  USER_SIGN_IN_FETCH_ERROR_PASSWORD,
  USER_SIGN_IN_FETCH_ERROR_UNKNOWN,
  USER_SIGN_IN_FETCH_ERROR_NETWORK,
  USER_SIGN_IN_FETCH_ERROR_EXPIRED,
  USER_SIGN_IN_FETCH_SUCCESS,
  PROPERTIES_FETCH_SUCCESS,
} from '../actionTypes';

const initialState = {
  fetching: false,
  signIn: {
    userName: null,
    password: null,
  },
  auth: {
    isAuthenticated: false,
    token: null,
    error: {
      userName: null,
      password: null,
      other: null,
    },
  },
  userData: {
    Address: null,
    Billing_Information: null,
    City: null,
    Email: null,
    First_Name: null,
    Homeowner_ID: null,
    Last_Name: null,
    Locked_Time: null,
    Phone_Number: null,
    Picture: null,
    State: null,
    Temporary_Password: null,
    Token: null,
    UserID: null,
    UserName: null,
    User_ID: null,
    User_Type: null,
    Zip: null,
    createdAt: null,
    deletedAt: null,
    updatedAt: null,
  },
  properties: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_INPUT_USERNAME: {
      return update(state, {
        signIn: {
          userName: {
            $set: action.payload,
          },
        },
        auth: {
          error: {
            $set: {
              userName: null,
              password: null,
              other: null,
            },
          },
        },
      });
    }
    case SET_INPUT_PASSWORD: {
      return update(state, {
        signIn: {
          password: {
            $set: action.payload,
          },
        },
        auth: {
          error: {
            $set: {
              userName: null,
              password: null,
              other: null,
            },
          },
        },
      });
    }
    case USER_SIGN_IN_FETCH: {
      return update(state, {
        fetching: {
          $set: true,
        },
      });
    }
    case USER_SIGN_IN_FETCH_DONE: {
      return update(state, {
        fetching: {
          $set: false,
        },
      });
    }
    case USER_SIGN_IN_FETCH_ERROR_USERNAME: {
      return update(state, {
        auth: {
          error: {
            $set: {
              userName: action.payload,
              password: null,
              other: null,
            },
          },
        },
      });
    }
    case USER_SIGN_IN_FETCH_ERROR_PASSWORD: {
      return update(state, {
        auth: {
          error: {
            $set: {
              userName: null,
              password: action.payload,
              other: null,
            },
          },
        },
      });
    }
    case USER_SIGN_IN_FETCH_ERROR_UNKNOWN: {
      return update(state, {
        auth: {
          error: {
            $set: {
              userName: null,
              password: null,
              other: 'Can not login. Please try again', // TODO: hardcoded message,
            },
          },
        },
      });
    }
    case USER_SIGN_IN_FETCH_ERROR_NETWORK: {
      return update(state, {
        auth: {
          error: {
            $set: {
              userName: null,
              password: null,
              other: 'Can not login. Please try again', // TODO: hardcoded message,
            },
          },
        },
      });
    }
    case USER_SIGN_IN_FETCH_ERROR_EXPIRED: {
      return update(state, {
        auth: {
          error: {
            $set: {
              userName: null,
              password: null,
              other: null,
            },
          },
        },
      });
    }
    case USER_SIGN_IN_FETCH_SUCCESS: {
      return update(state, {
        signIn: {
          $set: {
            userName: null,
            password: null,
          },
        },
        auth: {
          isAuthenticated: {
            $set: true,
          },
          token: {
            $set: action.payload.token,
          },
          error: {
            $set: {
              userName: null,
              password: null,
              other: null,
            },
          },
        },
        userData: {
          $set: action.payload.user,
        },
      });
    }
    case PROPERTIES_FETCH_SUCCESS: {
      return update(state, {
        properties: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}
