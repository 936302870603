import update from 'immutability-helper'

import {
	SELECTED_OPTION,
	FETCH_JOBS_SUCCESS,
	TOGGLE_PANEL_EXPANSION,
	SET_INITIAL_GROUP,
	SET_DETAILS_TEXT,
	SET_DETAILS_DATE,
	TOGGLE_DETAILS_MODAL,
	UPLOAD_PICTURE,
	CREATE_JOB_SUCCESS,
} from '../actionTypes'

const initialState = {
  selectedOptionsIds: ['5ca61739be07770008748f9d'],
  checkedOptions: {},
  checkedAdditionalInfo: {},
  jobTypes: null,
  expandedPanels: [0],
  jobDetails: '',
  jobDate: '',
  detailsModalOpen: false,
  currentProjectId: null,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SELECTED_OPTION: {
      const { option, group, groupIndex } = action.payload;
      return update(state, {
        selectedOptionsIds: {
          $set: [
            ...state.selectedOptionsIds.slice(0, groupIndex + 1),
            option.linksTo,
          ],
        },
        expandedPanels: {
          $set: [option.linksTo],
        },
        checkedOptions: {
          [group.id]: {
            $set: option.id,
          },
        },
      });
    }
    case SET_INITIAL_GROUP: {
      return update(state, {
        selectedOptionsIds: {
          $set: action.payload ? [action.payload] : [],
        },
        expandedPanels: {
          $set: action.payload ? [action.payload] : [],
        },
      });
    }
    case FETCH_JOBS_SUCCESS: {
      return update(state, {
        jobTypes: {
          $set: action.payload,
        },
      });
    }
    case TOGGLE_PANEL_EXPANSION: {
      const included = state.expandedPanels.includes(action.payload);
      const command = included ? '$splice' : '$push';
      const value = included
        ? [[state.expandedPanels.indexOf(action.payload), 1]]
        : [action.payload];
      return update(state, {
        expandedPanels: {
          [command]: value,
        },
      });
    }
    case SET_DETAILS_TEXT: {
      return update(state, {
        jobDetails: {
          $set: action.payload,
        },
      });
    }
    case SET_DETAILS_DATE: {
      return update(state, {
        jobDate: {
          $set: action.payload,
        },
      });
    }
    case TOGGLE_DETAILS_MODAL: {
      return update(state, {
        detailsModalOpen: {
          $set: !state.detailsModalOpen,
        },
      });
    }
    case UPLOAD_PICTURE: {
      return state;
    }
    case CREATE_JOB_SUCCESS: {
      return update(state, {
        detailsModalOpen: {
          $set: true,
        },
        currentProjectId: {
          $set: action.payload,
        },
      });
    }
    default:
      return state;
  }
}
