import React from 'react'
import { Provider } from 'react-redux'
import store from './src/redux/createStore'
import { ApolloProvider } from 'react-apollo'
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks'
import client from './src/utils/client'

export default (
	{ element } // eslint-disable-line react/prop-types
) => {
	return (
		<Provider store={store}>
			<ApolloProvider client={client}>
				<ApolloHooksProvider client={client}>
					{element}
				</ApolloHooksProvider>
			</ApolloProvider>
		</Provider>
	);
}
