import { put, takeLatest, call } from 'redux-saga/effects';
import {
  FETCH_JOBS,
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_ERROR,
  CREATE_JOB,
  CREATE_JOB_SUCCESS,
  CREATE_JOB_ERROR,
} from '../actionTypes';
import Api from '../../utils/api';

export function* fetchJobsAsync(action) {
  try {
    const res = yield call(Api.jobTypes.all, action.payload);
    yield put({
      type: FETCH_JOBS_SUCCESS,
      payload: res.data.jobTypes,
    });
  } catch (e) {
    yield put({ type: FETCH_JOBS_ERROR });
  }
}

export function* watchFetchJobsAsync() {
  yield takeLatest(FETCH_JOBS, fetchJobsAsync);
}

export function* createJobAsync(action) {
  try {
    const res = yield call(Api.createJob.create, action.payload);
    if (res.status !== 200) throw Error('Can\'t create job');
    yield put({
      type: CREATE_JOB_SUCCESS,
      payload: res.data.Project.Project_ID,
    });
  } catch (e) {
    yield put({ type: CREATE_JOB_ERROR });
  }
}

export function* watchCreateJobAsync() {
  yield takeLatest(CREATE_JOB, createJobAsync);
}

export function* confirmJobAsync(action) {
  try {
    const res = yield call(Api.createJob.create, action.payload);
    if (res.status !== 200) throw Error('Can\'t create job');
    yield put({
      type: CREATE_JOB_SUCCESS,
      payload: res.data.Project.Project_ID,
    });
  } catch (e) {
    yield put({ type: CREATE_JOB_ERROR });
  }
}

export function* watchConfirmJobAsync() {
  yield takeLatest(CREATE_JOB, confirmJobAsync);
}
