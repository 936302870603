import { IHome, IJob } from '../pages/jobs/index'

const d = new Date()
const base_img =
	'https://uploads.pl-internal.com/MDZiZDhjNjktMGUzMS00OWVjLTkwYWUtNGY1ZDc4YWRlZDgw/content/2016/05/1450640335_6e4a527602.jpg'
const base_img_2 =
	'https://images.unsplash.com/photo-1475855581690-80accde3ae2b?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&w=1000&q=80'
const base_img_3 =
	'https://img.manoramaonline.com/content/dam/mm/en/lifestyle/decor/images/2017/4/8/ren-home.jpg.image.784.410.jpg.image.784.410.jpg'

export const homes: IHome[] = [
	{
		id: 'home1',
		street_address: '1234 Dandelion Rd',
		avatar_uri: base_img,
	},
	{
		id: 'home2',
		street_address: '4567 Daisy St',
		avatar_uri: base_img,
	},
	{
		id: 'home3',
		street_address: '9647 Tulip Ct',
		avatar_uri: base_img,
		not_yet_ready: true,
	},
]

export const jobs: IJob[] = [
	{
		id: 'job1',
		home_id: 'home1',
		title: 'Door Replacement',
		description: 'Long description about the window framing process and what I’m going to need done',
		createdAt: d,
		range_start: new Date(Date.now() - 60e3 * 60 * 24 * 24),
		range_end: new Date(Date.now() - 60e3 * 60 * 24 * 20),
		selected_bid_id: 'bid1',
		completed_date: new Date(Date.now() - 60e3 * 60 * 24 * 4),
		bids: [
			{
				id: 'bid1',
				company_name: 'Big Kahuna Ltd',
				avatar_uri: base_img,
				last_message: "Can't wait to get started on this job!",
				bid_price_in_cents: 1100e2,
				createdAt: d,
				notification_count: 2,
			},
			{
				id: 'bid2',
				company_name: 'Gonzalez Roofing, Painting, and Construction Among Other Things',
				avatar_uri: base_img,
				last_message: 'We can come out between XXX and YYY to get the job done quickly',
				bid_price_in_cents: 900e2,
				createdAt: d,
			},
			{
				id: 'bid3',
				company_name: 'Gunther Family Window Framing',
				avatar_uri: base_img,
				bid_price_in_cents: 1111e2,
				createdAt: d,
			},
		],
		avatars: [base_img, base_img_2, base_img_3],
		icon_name: 'meeting_room',
	},
	{
		id: 'job2',
		home_id: 'home1',
		title: 'Window Frames',
		description: 'Long description about the window framing process and what I’m going to need done',
		createdAt: d,
		range_start: new Date(Date.now() - 60e3 * 60 * 24 * 24),
		range_end: new Date(Date.now() - 60e3 * 60 * 24 * 20),
		selected_bid_id: 'bid1',
		bids: [
			{
				id: 'bid4',
				company_name: 'Big Kahuna Ltd',
				avatar_uri: base_img,
				last_message: "Can't wait to get started on this job!",
				bid_price_in_cents: 1100e2,
				createdAt: d,
				notification_count: 2,
			},
			{
				id: 'bid5',
				company_name: 'Gonzalez Roofing, Painting, and Construction Among Other Things',
				avatar_uri: base_img,
				last_message: 'We can come out between XXX and YYY to get the job done quickly',
				bid_price_in_cents: 900e2,
				createdAt: d,
			},
			{
				id: 'bid6',
				company_name: 'Gunther Family Window Framing',
				avatar_uri: base_img,
				bid_price_in_cents: 1111e2,
				createdAt: d,
			},
		],
		avatars: [base_img_2, base_img, base_img_3],
		icon_name: 'border_all',
	},
	{
		id: 'job3',
		home_id: 'home1',
		title: 'Window Frames',
		description: 'Long description about the window framing process and what I’m going to need done',
		createdAt: d,
		range_start: new Date(Date.now() - 60e3 * 60 * 24 * 24),
		range_end: new Date(Date.now() - 60e3 * 60 * 24 * 20),
		bids: [
			{
				id: 'bid11',
				company_name: 'Big Kahuna Ltd',
				avatar_uri: base_img,
				last_message: "Can't wait to get started on this job!",
				bid_price_in_cents: 1100e2,
				createdAt: d,
				notification_count: 2,
			},
			{
				id: 'bid12',
				company_name: 'Gonzalez Roofing, Painting, and Construction Among Other Things',
				avatar_uri: base_img,
				last_message: 'We can come out between XXX and YYY to get the job done quickly',
				bid_price_in_cents: 900e2,
				createdAt: d,
			},
			{
				id: 'bid13',
				company_name: 'Gunther Family Window Framing',
				avatar_uri: base_img,
				bid_price_in_cents: 1111e2,
				createdAt: d,
			},
		],
		avatars: [base_img_3, base_img_2, base_img],
		icon_name: 'home',
	},
	{
		id: 'job4',
		home_id: 'home1',
		title: 'Window Frames',
		description: 'Long description about the window framing process and what I’m going to need done',
		createdAt: d,
		range_start: new Date(Date.now() - 60e3 * 60 * 24 * 24),
		range_end: new Date(Date.now() - 60e3 * 60 * 24 * 20),
		bids: [
			{
				id: 'bid24',
				company_name: 'Big Kahuna Ltd',
				avatar_uri: base_img,
				last_message: "Can't wait to get started on this job!",
				bid_price_in_cents: 1100e2,
				createdAt: d,
				notification_count: 2,
			},
			{
				id: 'bid25',
				company_name: 'Gonzalez Roofing, Painting, and Construction Among Other Things',
				avatar_uri: base_img,
				last_message: 'We can come out between XXX and YYY to get the job done quickly',
				bid_price_in_cents: 900e2,
				createdAt: d,
			},
			{
				id: 'bid26',
				company_name: 'Gunther Family Window Framing',
				avatar_uri: base_img,
				bid_price_in_cents: 1111e2,
				createdAt: d,
			},
		],
		avatars: [base_img, base_img_2, base_img_3],
		icon_name: 'swap_horiz',
	},
	{
		id: 'job5',
		home_id: 'home1',
		title: 'Window Frames',
		description: 'Long description about the window framing process and what I’m going to need done',
		createdAt: d,
		range_start: new Date(Date.now() - 60e3 * 60 * 24 * 24),
		range_end: new Date(Date.now() - 60e3 * 60 * 24 * 20),
		bids: [
			{
				id: 'bid51',
				company_name: 'Big Kahuna Ltd',
				avatar_uri: base_img,
				last_message: "Can't wait to get started on this job!",
				bid_price_in_cents: 1100e2,
				createdAt: d,
				notification_count: 2,
			},
			{
				id: 'bid52',
				company_name: 'Gonzalez Roofing, Painting, and Construction Among Other Things',
				avatar_uri: base_img,
				last_message: 'We can come out between XXX and YYY to get the job done quickly',
				bid_price_in_cents: 900e2,
				createdAt: d,
			},
			{
				id: 'bid53',
				company_name: 'Gunther Family Window Framing',
				avatar_uri: base_img,
				bid_price_in_cents: 1111e2,
				createdAt: d,
			},
		],
		avatars: [base_img, base_img_2, base_img_3],
		icon_name: 'home',
	},
	{
		id: 'job6',
		home_id: 'home1',
		title: 'Window Frames',
		description: 'Long description about the window framing process and what I’m going to need done',
		createdAt: d,
		range_start: new Date(Date.now() - 60e3 * 60 * 24 * 24),
		range_end: new Date(Date.now() - 60e3 * 60 * 24 * 20),
		bids: [
			{
				id: 'bid64',
				company_name: 'Big Kahuna Ltd',
				avatar_uri: base_img,
				last_message: "Can't wait to get started on this job!",
				bid_price_in_cents: 1100e2,
				createdAt: d,
				notification_count: 2,
			},
			{
				id: 'bid65',
				company_name: 'Gonzalez Roofing, Painting, and Construction Among Other Things',
				avatar_uri: base_img,
				last_message: 'We can come out between XXX and YYY to get the job done quickly',
				bid_price_in_cents: 900e2,
				createdAt: d,
			},
			{
				id: 'bid66',
				company_name: 'Gunther Family Window Framing',
				avatar_uri: base_img,
				bid_price_in_cents: 1111e2,
				createdAt: d,
			},
		],
		avatars: [base_img, base_img_2, base_img_3],
		icon_name: 'swap_horiz',
	},
]
