export const SET_INPUT_USERNAME = 'SET_INPUT_USERNAME'
export const SET_INPUT_PASSWORD = 'SET_INPUT_PASSWORD'
export const USER_SIGN_IN_FETCH = 'USER_SIGN_IN_FETCH'
export const USER_SIGN_IN_FETCH_DONE = 'USER_SIGN_IN_FETCH_DONE'
export const USER_SIGN_IN_FETCH_SUCCESS = 'USER_SIGN_IN_FETCH_SUCCESS'
export const USER_SIGN_IN_FETCH_ERROR_NETWORK = 'USER_SIGN_IN_FETCH_ERROR_NETWORK'
export const USER_SIGN_IN_FETCH_ERROR_USERNAME = 'USER_SIGN_IN_FETCH_ERROR_USERNAME'
export const USER_SIGN_IN_FETCH_ERROR_PASSWORD = 'USER_SIGN_IN_FETCH_ERROR_PASSWORD'
export const USER_SIGN_IN_FETCH_ERROR_UNKNOWN = 'USER_SIGN_IN_FETCH_ERROR_UNKNOWN'
export const USER_SIGN_IN_FETCH_ERROR_EXPIRED = 'USER_SIGN_IN_FETCH_ERROR_EXPIRED'

export const PROPERTIES_FETCH = 'PROPERTIES_FETCH'
export const PROPERTIES_FETCH_SUCCESS = 'PROPERTIES_FETCH_SUCCESS'
export const PROPERTIES_FETCH_ERROR = 'PROPERTIES_FETCH_ERROR'

export const SELECTED_OPTION = 'SELECTED_OPTION'
export const SET_INITIAL_GROUP = 'SET_INITIAL_GROUP'
export const TOGGLE_PANEL_EXPANSION = 'TOGGLE_PANEL_EXPANSION'
export const SET_DETAILS_TEXT = 'SET_DETAILS_TEXT'
export const SET_DETAILS_DATE = 'SET_DETAILS_DATE'

export const ADD_HOME = 'ADD_HOME'

export const FETCH_JOBS = 'FETCH_JOBS'
export const FETCH_JOBS_SUCCESS = 'FETCH_JOBS_SUCCESS'
export const FETCH_JOBS_ERROR = 'FETCH_JOBS_ERROR'

export const CREATE_JOB = 'CREATE_JOB'
export const CREATE_JOB_SUCCESS = 'CREATE_JOB_SUCCESS'
export const CREATE_JOB_ERROR = 'CREATE_JOB_ERROR'
export const TOGGLE_DETAILS_MODAL = 'TOGGLE_DETAILS_MODAL'
export const UPLOAD_PICTURE = 'UPLOAD_PICTURE'
