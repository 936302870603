import axiosRestClient from 'axios-rest-client';

const Api = axiosRestClient({
  baseUrl: process.env.GATSBY_API_BASE_URL,
  timeout: process.env.GATSBY_REQUEST_TIMEOUT,
});

Api.endpoints({
  signin: 'signin',
  jobTypes: 'jobType/all/hardcoded',
  createJob: 'project/create',
  findProperties: 'homeSpecs/property',
  uploadPropertyPicture: 'storage/project',
});

export const setHeader = (header, value) => {
  Api.setHeader(header, value);
};

export default Api;
