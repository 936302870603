import { put, takeLatest, call } from 'redux-saga/effects';
import {
  USER_SIGN_IN_FETCH,
  USER_SIGN_IN_FETCH_SUCCESS,
  USER_SIGN_IN_FETCH_DONE,
  USER_SIGN_IN_FETCH_ERROR_USERNAME,
  USER_SIGN_IN_FETCH_ERROR_PASSWORD,
  USER_SIGN_IN_FETCH_ERROR_NETWORK,
  USER_SIGN_IN_FETCH_ERROR_UNKNOWN,
} from '../actionTypes';
import Api from '../../utils/api';

export function* fetchUserAsync(action) {
  try {
    const createSessionRes = yield call(Api.signin.create, action.payload);
    yield put({ type: USER_SIGN_IN_FETCH_DONE });
    if (createSessionRes.data.auth.error) {
      switch (createSessionRes.data.auth.message) {
        case 'User not found':
          yield put({
            type: USER_SIGN_IN_FETCH_ERROR_USERNAME,
            payload: createSessionRes.data.auth.message,
          });
          break;
        case 'Incorrect Password':
          yield put({
            type: USER_SIGN_IN_FETCH_ERROR_PASSWORD,
            payload: createSessionRes.data.auth.message,
          });
          break;
        default:
          yield put({ type: USER_SIGN_IN_FETCH_ERROR_UNKNOWN });
      }
    } else {
      yield put({
        type: USER_SIGN_IN_FETCH_SUCCESS,
        payload: createSessionRes.data.auth,
      });
    }
  } catch (e) {
    yield put({ type: USER_SIGN_IN_FETCH_DONE });
    yield put({ type: USER_SIGN_IN_FETCH_ERROR_NETWORK });
  }
}

export function* watchFetchUserAsync() {
  yield takeLatest(USER_SIGN_IN_FETCH, fetchUserAsync);
}
