/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { getTokenUserId } from './src/utils/client';
import wrapWithProvider from './wrap-with-provider-browser';
import LogRocket from 'logrocket';

export const wrapRootElement = wrapWithProvider; // eslint-disable-line import/prefer-default-export

export const onClientEntry = () => {
  if (process.env.NODE_ENV === 'production') {
    LogRocket.init('jichkb/fairmarket');
  }
}
